module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ace Volley App","short_name":"Ace","start_url":"/","icon":"src/assets/img/favicon.png","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4ec4890ac0a27c7ca8b9e385c9ce2074"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","ua"],"defaultLanguage":"en","defaultNS":"translation","siteUrl":"https://acevolleyapp.com","trailingSlash":"always","generateDefaultLanguagePage":true,"i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/terms-and-conditions","languages":["en","ua"],"getLanguageFromPath":false},{"matchPath":"/:lang?/privacy-policy","languages":["en","ua"],"getLanguageFromPath":false}]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
